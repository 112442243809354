import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
// import blogData from './blogData'; 
import BlogData from './blogData';
import Fermi from '../assets/Fermi.png';
import Gandhi from '../assets/Gandhi.png';
import LKY from '../assets/LKY.png';
import Nakamoto from '../assets/Nakamoto.png';
import MalcolmX from '../assets/MalcolmX.png';
import Newton from '../assets/Newton.png';
import Confucius from '../assets/Confucius.png';
import Cleopatra from '../assets/Cleopatra.png';
import Machiavelli from '../assets/Machiavelli.png';
import MLK from '../assets/MLK.png';
import Tesla from '../assets/Tesla.png';
import Churchill from '../assets/Churchill.png';
import Image1 from '../assets/Aristotle.png';
import Image2 from '../assets/Plato.png';
import Image3 from '../assets/Darwin.png';
import Image4 from '../assets/Marcus.png';
import Image5 from '../assets/Oppenheimer.png';
import Image6 from '../assets/ZahaHadid.png';
import '../components/HomePage.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import appStoreImg from '../assets/1.png';
import VideoHomepage1 from '../assets/ikea.mp4';
import VideoHomepage2 from '../assets/Web3_011.mp4';
import VideoHomepage3 from '../assets/Web3_022.mp4';
import VideoHomepage4 from '../assets/Web3_033.mp4';
import VideoHomepage5 from '../assets/Web3_044.mp4';
import VideoHomepage6 from '../assets/Web3_055.mp4';

const images = [Fermi, Gandhi, LKY, Nakamoto, MalcolmX, Newton, Confucius, Cleopatra, Machiavelli, MLK, Tesla, Churchill, Image1, Image2, Image3, Image4, Image5, Image6];
const images1 = [Fermi, Image5, Gandhi, LKY, Nakamoto, MalcolmX];
const images2 = [Newton, Confucius, Image4, Cleopatra, Machiavelli, MLK];
const images3 = [Tesla, Churchill, Image1, Image2, Image3, Image6];

const Carousel = () => {


  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const moveCarousel = () => {
      setOffset((prevOffset) => {
        if (prevOffset <= -1000) {
          return 0;
        }
        return prevOffset - 1;
      });
    };

    const id = setInterval(moveCarousel, 10);
    return () => clearInterval(id);
  }, []);

  return (
    <div className="carousel-container">
      <div className="carousel-wrapper" style={{ transform: `translateX(${offset}px)` }}>
        {images1.map((image, index) => (
          <img key={index} src={image} alt="" className="carousel-image" />
        ))}
        {images1.map((image, index) => (
          <img key={index + images1.length} src={image} alt="" className="carousel-image" />
        ))}
      </div>
    </div>
  );
};

const ReverseCarousel = () => {
  const [offset, setOffset] = useState(-1000);

  useEffect(() => {
    const moveCarousel = () => {
      setOffset((prevOffset) => {
        if (prevOffset >= 0) {
          return -1000;
        }
        return prevOffset + 1;
      });
    };

    const id = setInterval(moveCarousel, 40);
    return () => clearInterval(id);
  }, []);

  return (
    <div className="reverse-carousel-container">
      <div className="reverse-carousel-wrapper" style={{ transform: `translateX(${offset}px)` }}>
        {images2.map((image, index) => (
          <img key={index} src={image} alt="" className="reverse-carousel-image" />
        ))}
        {images2.map((image, index) => (
          <img key={index + images2.length} src={image} alt="" className="reverse-carousel-image" />
        ))}
      </div>
    </div>
  );
};

const AnotherCarousel = () => {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const moveCarousel = () => {
      setOffset((prevOffset) => {
        if (prevOffset <= -1000) {
          return 0;
        }
        return prevOffset - 1;
      });
    };

    const id = setInterval(moveCarousel, 10);
    return () => clearInterval(id);
  }, []);

  return (
    <div className="another-carousel-container">
      <div className="another-carousel-wrapper" style={{ transform: `translateX(${offset}px)` }}>
        {images3.map((image, index) => (
          <img key={index} src={image} alt="" className="another-carousel-image" />
        ))}
        {images3.map((image, index) => (
          <img key={index + images3.length} src={image} alt="" className="another-carousel-image" />
        ))}
      </div>
    </div>
  );
};

const BlogCarousel = () => {
  const blogData = BlogData();
  const sortedBlogData = blogData.sort((a, b) => new Date(b.date) - new Date(a.date));

  const settings = {
    dots: true,
    infinite: false,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className="blog-carousel-container">
      <Slider {...settings}>
        {sortedBlogData.map((blog, index) => (
          <div className="blog-slide" key={index}>
            <Link to={`/blog/${blog.slug}`} className="blog-post">
              <div className="blog-post">
                <div className="blog-image-wrapper">
                  <img src={blog.image} alt="Blog Image 1" />
                </div>
                <div className="blog-info">
                  <h3>{blog.title}</h3>
                  <p className="blog-date">
                    {blog.selectedDate && blog.selectedDate !== "" ? (
                      new Date(blog.selectedDate).toLocaleString('en-US', {
                        month: 'short',
                        day: '2-digit',
                        year: 'numeric'
                      })
                    ) : (
                      new Date(blog.createdAt).toLocaleString('en-US', {
                        month: 'short',
                        day: '2-digit',
                        year: 'numeric'
                      })
                    )}
                  </p>
                </div>
              </div>
            </Link>
          </div>

        ))}
      </Slider>
    </div>
  );
};

function HomePage() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [loading, setLoading] = useState('done');
  const [currentPage, setCurrentPage] = useState('home');

  useEffect(() => {
    const handleScroll = () => {
      const videoContainers = document.querySelectorAll('.video-container-homepage');

      videoContainers.forEach((container, index) => {
        const rect = container.getBoundingClientRect();
        const elementTop = rect.top;
        const elementVisible = 100;

        if (elementTop < window.innerHeight - elementVisible) {
          container.classList.add('visible');
          container.classList.add(index % 2 === 0 ? 'slide-from-left' : 'slide-from-right');
        } else {
          container.classList.remove('visible', 'slide-from-left', 'slide-from-right');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  if (loading === 'loading') {
    return <div className="loading-container">Loading...</div>;
  }

  if (loading === 'fading') {
    return <div className="loading-container fade-out"></div>;
  }

  //  if (currentPage === 'AboutUs') {
  //  return <AboutUs />;
  //}

  return (
    <div className="App">
      <Helmet>
        <title>Home - Akadimia AI</title>
        <meta name="description" content="Step into the future of learning with Akadimia AI, an immersive educational app that transforms your device into a portal to the past." />
      </Helmet>
      <div className="new-banner">
        <div className="new-banner-text">
          <h1 className="new-title">LEARN LIKE A LEGEND, <br /> BECOME A LEGEND.</h1>
          <p className="new-description">
            Step into the future of learning with Akadimia AI,<br /> an immersive educational platform that brings history's icons as your AI mentor.
          </p>
          <a href="https://apps.apple.com/us/app/akadimia-ai/id1604169673" className="app-download-button">
            <img src={appStoreImg} alt="Download on the App Store" className="download-icon" />
            Get Akadimia Now
          </a>
        </div>
        <div className="new-banner-video">
          <video autoPlay loop playsInline muted>
            <source src={VideoHomepage1} type="video/mp4" />
          </video>
        </div>
      </div>
      <BlogCarousel />
      <div className="ai-avatars-section">
        <h1 className="ai-avatars-title">World's <span className="color-changing-text">Greatest Minds</span> at your Fingertips!</h1>
      </div>
      <Carousel />
      <div className="carousel-spacing"></div>
      <ReverseCarousel />
      <div className="carousel-spacing"></div>
      <AnotherCarousel />
      <div className="carousel-spacing"></div>
      <div className="description-content">
        <div className="AR-description-text">
          <h2 style={{ letterSpacing: '2px' }}>AI Historical Journey</h2>
          <p style={{ textAlign: 'justify', fontSize: "28px" }}>More than just an app, but a transformative learning experience that seamlessly blends history, technology, and education. Witness the convergence of these disciplines as you engage in real-time conversations with the historical figures in our platform.</p>
        </div>
      </div>
      <div className="video-container-homepage-parent">
        <div className={`video-container-homepage slide-from-left`}>
          <video autoPlay loop muted playsInline>
            <source src={VideoHomepage2} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <p >Explore AI Avatars In the AI Library</p>
        </div>
        <div className={`video-container-homepage slide-from-right`}>
          <p>Experience Different<br /> Modes of Interaction</p>
          <video autoPlay loop muted playsInline>
            <source src={VideoHomepage3} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        {/* Repeat this for the rest of the videos */}
        <div className={`video-container-homepage slide-from-left`}>
          <video autoPlay loop muted playsInline>
            <source src={VideoHomepage4} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <p>Chat with Your Favorite Avatars</p>
        </div>
        <div className={`video-container-homepage slide-from-right`}>
          <p>Navigate through Different Times and Eras</p>
          <video autoPlay loop muted playsInline>
            <source src={VideoHomepage5} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className={`video-container-homepage slide-from-left`}>
          <video autoPlay loop muted playsInline>
            <source src={VideoHomepage6} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <p>Over 40 Lessons and More to Come</p>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
