import React, { useEffect } from 'react';
import './HowItWorks.css';
import AILibrary from '../assets/AILibrary.png';
import Howitworks2 from '../assets/Howitworks2.png';
import Howitworks3 from '../assets/Howitworks3.png';
import Howitworks4 from '../assets/Howitworks4.png';
import Howitworks5 from '../assets/Howitworks5.png';
import Howitworks6 from '../assets/Howitworks6.png';
import Howitworks7 from '../assets/AVP.png';
import AILibraryVideo from '../assets/1AILibrary.mp4';
import Video3D from '../assets/3D.mp4';
import VideoAR from '../assets/AR.mp4';
import VideoChat from '../assets/Chat.mp4';
import VideoTM from '../assets/TimeMachine.mp4';
import VideoUNI from '../assets/University.mp4';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';


const HowItWorks = () => {
  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 1500, // animations will take 1 second
      delay: 0,        // Delay in milliseconds.
      easing: 'ease',  // Choose timing function to ease elements in.
      once: false,     // Whether animation should happen only once.
      mirror: false,   // Whether elements should animate when scrolling up.
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>About Us - Akadimia AI</title>
        <meta name="description" content="Learn about Akadimia AI, a technology company that develops innovative educational apps using artificial intelligence (AI). Our mission is to make history education more accessible and engaging for everyone." />
      </Helmet>
      <div className="howitworks-container">
        <div data-aos="fade-up" className="howitworks-scroll-section howitworks-black-bg" id="step1">
          <h1>How it works?</h1>
          <div className="howitworks-content">
            <div className="video-container">
              <video width="100%" controls muted loop autoPlay>
                <source src={AILibraryVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="howitworks-text">
              <h2>AI Library</h2>
              <p>The Al Library provides a diverse selection of historical figures in the form of Al avatars, enabling various interactive experiences such as 3D interactions, AR engagement, and chat sessions. To initiate an interaction, simply tap on a historical figure from the Ai library.</p>
            </div>
          </div>
        </div>

        <div data-aos="fade-up" className="howitworks-scroll-section howitworks-white-bg" id="step2">
          <div className="howitworks-content">
            <div className="video-container">
              <video width="100%" controls muted loop autoPlay>
                <source src={Video3D} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="howitworks-text">
              <h2>3D Mode</h2>
              <p>Begin an extraordinary journey through time as you step into a mesmerizing 3D environment, where the past comes to life in vivid detail. Interact with any historical figure of your choice, and experience a new level of engagement like never before. Explore history in a way that feels real and enriching, making your interactions with these iconic figures an unforgettable educational experience.</p>
            </div>
          </div>
        </div>

        <div data-aos="fade-up" className="howitworks-scroll-section howitworks-black-bg" id="step3">
          <div className="howitworks-content">
            <div className="video-container">
              <video width="100%" controls muted loop autoPlay>
                <source src={VideoAR} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="howitworks-text">
              <h2>AR Mode</h2>
              <p>Bring historical figures to life with AR as you place the Al avatar right in front of you. Summon your favorite historical character to your living room, office, or any outdoor location of your choice.</p>
            </div>
          </div>
        </div>

        <div data-aos="fade-up" className="howitworks-scroll-section howitworks-white-bg" id="step4">
          <div className="howitworks-content">
            <div className="video-container">
              <video width="100%" controls muted loop autoPlay>
                <source src={VideoChat} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="howitworks-text">
              <h2>Chat Mode</h2>
              <p>Engage in conversations with historical figures through the Al avatar, just as you would with friends. Pose any question you have, and receive instant answers, making learning from these historical figures a seamless and interactive experience.</p>
            </div>
          </div>
        </div>

        <div data-aos="fade-up" className="howitworks-scroll-section howitworks-black-bg" id="step5">
          <div className="howitworks-content">
            <div className="video-container">
              <video width="100%" controls muted loop autoPlay>
                <source src={VideoTM} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="howitworks-text">
              <h2>Time Machine</h2>
              <p>Time Machine allows you to explore different centuries and learn about the historical figures and impactful events that happened during that time period. Simply scroll to select a century and then start exploring.</p>
            </div>
          </div>
        </div>

        <div data-aos="fade-up" className="howitworks-scroll-section howitworks-white-bg" id="step6">
          <div className="howitworks-content">
            <div className="video-container">
              <video width="100%" controls muted loop autoPlay>
                <source src={VideoUNI} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="howitworks-text">
              <h2>AI University</h2>
              <p>University offers a wide range of lessons on a variety of topics, all taught directly from the AI avatars of the historical figures that were the protagonists. Tap on the lesson you want to receive and start learning from the best.</p>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" className="howitworks-scroll-section howitworks-black-bg" id="step7">
          <div className="howitworks-content last">
            <img className="big-centered-image" src={Howitworks7} alt="How it works 7" />
            <div className="howitworks-text">
              <h2>Expected February 2024</h2>
              <p> Akadimia Ai will be on Apple vision pro, Stay Tuned..</p>
            </div>
          </div>
        </div>
      </div>
    </>

  );
};


export default HowItWorks;
