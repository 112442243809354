import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import './BlogPostDetail.css';
// import blogData from './blogData';
import BlogData from './blogData';
import { Helmet } from 'react-helmet';


const BlogPostDetail = ({ setHeaderBgColor }) => {
  // Use 'slug' from the URL instead of 'id'
  const { slug } = useParams();
  const blogData = BlogData();

  // Find the blog post by matching the slug
  const blogPost = blogData.find(blog => {
    // Assuming blogData is updated to include a 'slug' property for each post
    return blog.slug === slug;
  });

  useEffect(() => {
    if (blogPost) {
      setHeaderBgColor(blogPost.bgColor);
    }
    // Reset color when component unmounts
    return () => setHeaderBgColor('defaultColor'); // Replace 'defaultColor' with your actual default color
  }, [blogPost, setHeaderBgColor]);

  if (!blogPost) {
    return <div>Blog post not found</div>;
  }

  // Dynamically set the background color for the blog post
  const blogPostStyle = {
    backgroundColor: blogPost.bgColor,
  };

  return (
    <>
      <Helmet>
        <title>{blogPost.title} - Akadimia AI</title>
        <meta name="description" content={blogPost.metaDescription} />
        <meta property="og:title" content={`${blogPost.title} - Akadimia AI`} />
        <meta property="og:description" content={blogPost.metaDescription} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://akadimia.ai/blog/${blogPost.slug}`} />
        <meta property="og:image" content={blogPost.image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${blogPost.title} - Akadimia AI`} />
        <meta name="twitter:description" content={blogPost.metaDescription} />
        <meta name="twitter:image" content={blogPost.image} />
        <link rel="canonical" href={`https://akadimia.ai/blog/${blogPost.slug}`} />
        <script type="application/ld+json">
          {`
  {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "headline": "${blogPost.title}",
    "image": "${blogPost.image}",
    "datePublished": "${blogPost.date}",
    "description": "${blogPost.metaDescription}",
    "author": {
      "@type": "Person",
      "name": "${blogPost.author}"
    },
    "publisher": {
      "@type": "Organization",
      "name": "Akadimia AI",
      "logo": {
        "@type": "ImageObject",
        "url": "https://akadimia.ai/static/media/akadimia-logo.799c28a2b8a9ac615836.png"
      }
    }
  }
`}
        </script>
      </Helmet>
      <div className="blog-post-detail">
        <div className="blog-detail-colored" style={blogPostStyle}>
          <Link to="/blog" className="back-to-blog-button">Blog</Link>
          <div className="blog-detail-header">
            <div className="title-and-h2">
              <h1 className="blog-detail-title">{blogPost.title}</h1>
              <h2 className="blog-detail-h2">{blogPost.subtitle}</h2>
            </div>
            <img src={blogPost.image} alt={blogPost.title} className="blog-detail-image" />
          </div>
        </div>
        <div className="blog-detail-line"></div>
        <div className="blog-detail-content">
          <p className="blog-date1">
            {blogPost.selectedDate && blogPost.selectedDate !== "" ? (
              new Date(blogPost.selectedDate).toLocaleString('en-US', {
                month: 'short',
                day: '2-digit',
                year: 'numeric'
              })
            ) : (
              new Date(blogPost.createdAt).toLocaleString('en-US', {
                month: 'short',
                day: '2-digit',
                year: 'numeric'
              })
            )}
          </p>
          <div className="blog-content" dangerouslySetInnerHTML={{ __html: blogPost.content }}></div>
        </div>
      </div>
    </>

  );

};

export default BlogPostDetail;
