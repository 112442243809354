import React from 'react';
import { Helmet } from 'react-helmet';
import './AboutUsPage.css';
import characters from '../assets/Characters-2.png';
import lineImg from '../assets/Line2.png';
import appStoreImg from '../assets/AppStore.png';
import ellipseR from '../assets/Ellipser.png';
import ellipseW from '../assets/EllipseW.png';
import ellipseB from '../assets/EllipseB.png';


const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>About Us - Akadimia AI</title>
        <meta name="description" content="Learn about Akadimia AI, a technology company that develops innovative educational apps using artificial intelligence (AI). Our mission is to make history education more accessible and engaging for everyone." />
      </Helmet>
      <div className="about-us-container">
        <img src={ellipseR} alt="Ellipse Middle Left" className="ellipse-middle-left" />
        <img src={ellipseW} alt="Ellipse Right Top" className="ellipse-right-top" />
        <img src={ellipseB} alt="Ellipse Right Bottom" className="ellipse-right-bottom" />
        <img src={ellipseR} alt="Ellipse Bottom Right" className="ellipse-bottom-right" />
        <div className="background-wrapper">
          <img className="background-image" src={characters} alt='Characters' />
          <div className="overlay-text">
            <h1 className="welcome-text">WELCOME TO AKADIMIA AI</h1>
            <div className="description-text">
              Akadimia AI is a technology company that develops innovative educational apps using artificial intelligence (AI).
              Our app allows users to interact with AI historical figures in augmented reality (AR).
            </div>
            <div className="download-btn">
              <a href="https://apps.apple.com/us/app/akadimia-ai/id1604169673" target="_blank" rel="noopener noreferrer">
                <img src={appStoreImg} alt='Download from App Store' />
              </a>
            </div>
          </div>
        </div>
        <div className="white-section">
          <div className="mission-section" >
            <h2 className="section-title" >OUR MISSION </h2>
            <div className="section-content" >
              Our mission is to make history education more
              accessible and engaging for everyone. We believe
              that AI has the potential to revolutionize the way we
              learn about history, and we are committed to developing
              innovative educational apps that use this technology.
            </div>
          </div>
          <div className="impact-section">
            <h2 className="section-title">OUR IMPACT</h2>
            <div className="section-content">
              We believe that our Akadimia AI has the potential to make a significant impact on the way people learn, by taking advantage of the latest and trending technologies of communication.
            </div>
          </div>
        </div>
        <div className="line-section">
          <img src={lineImg} alt='Line' />
        </div>
        <div className="low-text">
          We're EXCITED TO SEE HOW AKADIMIA WILL HELP
          PEOPLE LEARN AND GROW IN THE YEARS TO COME.
        </div>
      </div>
    </>

  );
};

export default AboutUs;
