import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import HomePage from './components/HomePage';
import Footer from './components/Footer';
import OverlayMenu from './components/OverlayMenu';
import AboutUsPage from './components/AboutUsPage';
import ContactUsPage from './components/ContactUsPage';
import HowItWorks from './components/HowItWorks';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import Blog from './components/Blog';
import BlogPostDetail from './components/BlogPostDetail';
import './App.css';
import ReactPixel from 'react-facebook-pixel';
import axios from 'axios';



const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};

ReactPixel.init('YOUR_FB_PIXEL_ID', options);

const RedirectToDownload = () => {
  useState(() => {
    window.location.href = 'https://apps.apple.com/us/app/akadimia-ai/id1604169673';
  }, []);

  return null;
};

const RedirectToFreeTrial = () => {
  useState(() => {
    window.location.href = 'https://apps.apple.com/redeem?ctx=offercodes&id=1604169673&code=FUTUREISHERE';
  }, []);

  return null;
};

const App = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [headerBgColor, setHeaderBgColor] = useState('defaultColor'); // Default header background color
  const toggleMenu = () => setMenuOpen(!menuOpen);
  const [tags, setTags] = useState([]);
  const metaKeywords = tags.join(", ");


  useEffect(() => {
    // Fetch blog data from the server
    const fetchBlogData = async () => {
        try {
            const response = await axios.get('https://server-puce-iota.vercel.app/api/blogs');
            const data = response.data;

            // Extract metaTags from each blog object and update state
            const allMetaTags = data.flatMap(blog => blog.metaTags); // flatMap() is used to flatten the resulting array
            setTags(allMetaTags);

        } catch (error) {
            console.error('Error fetching blog data:', error.message);
        }
    };

    // Call the fetch function
    fetchBlogData();
}, []);


  return (
    <Router>
       <meta name="keywords" content={metaKeywords} />
      <div className="App">
        <Header menuOpen={menuOpen} toggleMenu={toggleMenu} backgroundColor={headerBgColor} />
        <OverlayMenu isOpen={menuOpen} toggleMenu={toggleMenu} />
        <div className={`content ${menuOpen ? 'blurred' : ''}`}>
          <Routes>
            <Route path="/" element={<HomePage setHeaderBgColor={setHeaderBgColor} />} />
            <Route path="/about-us" element={<AboutUsPage />} />
            <Route path="/howitworks" element={<HowItWorks />} />
            <Route path="/contact-us" element={<ContactUsPage />} />
            <Route path="/home" element={<HomePage setHeaderBgColor={setHeaderBgColor} />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/download" element={<RedirectToDownload />} />
            <Route path="/blog" element={<Blog metaKeywords={metaKeywords} setHeaderBgColor={setHeaderBgColor} />} />
            <Route path="/freetrial" element={<RedirectToFreeTrial />} />
            <Route path="/blog/:slug" element={<BlogPostDetail setHeaderBgColor={setHeaderBgColor} />} />
          </Routes>
          <Footer />
        </div>
      </div>
    </Router>
  );
};

export default App;