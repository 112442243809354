import React from 'react';
import './Header.css';
import logoFullWR1 from '../assets/akadimia-logo.png';  // Update the path
import MenuOpenImage from '../assets/menu-btn.png';  // Update the path
import MenuCloseImage from '../assets/MenuClose.png';  // Update the path

function Header({ menuOpen, toggleMenu, backgroundColor }) {
  const headerStyle = {
    filter: menuOpen ? 'none' : '',
    zIndex: menuOpen ? 10000 : '',
    backgroundColor: backgroundColor || 'rgb(255, 255, 255)'  // Default background color
  };


  return (
    <>
      <header className="App-header Header" style={headerStyle}>
        <div className="logo-container">
          <a href="/home">
            <img src={logoFullWR1} alt="logo" className="logo" />
          </a>
        </div>
        <button onClick={toggleMenu} className={`menu-button ${menuOpen ? 'no-blur' : ''}`}>
          {menuOpen ? (
            <button className="menu-close-button">X</button>
          ) : (
            <img src={MenuOpenImage} alt="Open Menu" className="menu-open-img" />
          )}
        </button>
      </header>
    </>

  );
}

export default Header;