import React from 'react';
import ReactDOM from 'react-dom'; // Import ReactDOM for createPortal
import { Link } from 'react-router-dom';
import './OverlayMenu.css';
import tiktok from '../assets/tiktok.png';
import instagram from '../assets/instagram.png';
import twitter from '../assets/twitter.png';
import youtube from '../assets/youtube.png';

function OverlayMenu({ isOpen, toggleMenu }) {
  // Create the JSX for the OverlayMenu
  const menu = (
    <div className={`overlay-menu ${isOpen ? 'open' : ''}`}>
      <div className="menu-links">
        <a href="/about-us">About</a>
        <a href="/howitworks">How It Works</a>
        <a href="mailto:social@akadimia.ai">Contact</a>
        <a href="/blog">Blog</a>
      </div>
      <div className="menu-social-icons">
        <a href="https://www.tiktok.com/@akadimiaai" target="_blank" rel="noopener noreferrer">
          <img src={tiktok} alt="TikTok" />
        </a>
        <a href="https://www.instagram.com/akadimiaai/" target="_blank" rel="noopener noreferrer">
          <img src={instagram} alt="Instagram" />
        </a>
        <a href="https://twitter.com/AkadimiaAi" target="_blank" rel="noopener noreferrer">
          <img src={twitter} alt="Twitter" />
        </a>
        <a href="https://www.youtube.com/@akadimiaai8448/featured" target="_blank" rel="noopener noreferrer">
          <img src={youtube} alt="YouTube" />
        </a>
      </div>
    </div>
  );
  

  // Use ReactDOM.createPortal to render the menu in the "overlay-root" div
  return ReactDOM.createPortal(menu, document.getElementById('overlay-root'));
}

export default OverlayMenu;
