import React from 'react';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
    return (
        <article className="terms-conditions-container">
            <h1>End User License Agreement</h1>
            <div className="entry-content">
                    <p>Welcome to AKADIMIA AI</p>

                    <p>These Terms of Service (“Terms”) govern your use of AKADIMIA AI and the software, content, and services
                        (collectively, “Services”) offered through: our website www.akadimia.ai and its subdomains (the “Website”),
                        our mobile app AKADIMIA AI for iOS (the “Apps”).</p>

                    <p>Please read these terms carefully before you start using the Services.</p>

                    <p>The terms “AKADIMIA AI,” “us” or “we” refers to AKADIMIA AI, a software company who designed and built
                        AKADIMIA AI, incorporated in Illinois, and operating in Chicago.</p>

                    <p>The term “device” refers to the device which is used to access the Services including but not limited to
                        computers, smartphones and tablets.</p>

                    <p>The term “you” refers to the user of the Services.</p>

                    <p>When you sign up for any of the Services or otherwise use or access them, you agree to be bound by these
                        Terms and all applicable laws, rules, and regulations. By using the Services, you indicate that you accept
                        these Terms and that you agree to abide by them. If you do not agree to these Terms, please refrain from
                        using the services.</p>

                    <p>Our contact email address is support@akadimia.ai All correspondence to AKADIMIA AI including any queries you
                        may have regarding your use of the Services or these Terms should be sent to this contact email address.</p>

                    <p>1. You agree that AKADIMIA AI, in its sole discretion, may suspend or terminate your account (or any part
                        thereof) or use of the Service and remove and discard any content within the Service, for any reason,
                        including, without limitation, for lack of use or if AKADIMIA AI believes that you have violated or acted
                        inconsistently with the letter or spirit of these Terms of Service. Any suspected fraudulent, abusive or
                        illegal activity that may be grounds for termination of your use of Service, may be referred to appropriate
                        law enforcement authorities. AKADIMIA AI may also in its sole discretion and at any time discontinue
                        providing the Service, or any part thereof, with or without notice. You agree that any termination of your
                        access to the Service under any provision of these Terms of Service may be effected without prior notice,
                        and acknowledge and agree that AKADIMIA AI may immediately deactivate or delete your account and all related
                        information and files in your account and/or bar any further access to such files or the Service. Further,
                        you agree that AKADIMIA AI will not be liable to you or any third party for any termination of your access
                        to the Service.</p>

                    <p>2. Modifications to Services
                        We reserve the right to modify or discontinue, temporarily or permanently, the Services (or any part
                        thereof) with or without notice. You agree that AKADIMIA AI will not be liable to you or to any third party
                        for any modification, suspension, or discontinuance of any of the Services.
                    </p>

                    <div>
                        <p>3. Account Creation</p>
                        <div className="sub-para">
                            <p>3.1. Account Registration</p>
                            You will be required to register (“create an account”) with AKADIMIA AI and become a user in order to
                            access and use the Services. If you choose to register for the Services, you agree to provide and
                            maintain true, accurate, current, and complete information about yourself as prompted by the
                            registration forms. Registration data and certain other information about you are governed by our
                            Privacy Policy.
                        </div>
                        <div className="sub-para">
                            <p>3.2. Once the account is created</p>
                            You are responsible for maintaining the confidentiality of your password and account, if any, and are
                            fully responsible for any and all activities that occur under your password or account. You agree to (a)
                            immediately notify AKADIMIA AI of any unauthorized use of your password or account or any other breach
                            of security, and (b) ensure that you exit from your account at the end of each session when accessing
                            the Services. AKADIMIA AI will not be liable for any loss or damage arising from your failure to comply
                            with this Section.
                        </div>
                        <div className="sub-para">
                            <p>3.3. Your Account</p>
                            As an AKADIMIA AI user, you will receive access to content, features, and functions of the Services that
                            are not available to non-subscribers. By agreeing to become a Member you opt-in to receive occasional
                            special offers, marketing, survey, and Services-based communication emails. You can easily unsubscribe
                            from AKADIMIA commercial emails by following the opt-out instruction in these emails. AKADIMIA AI
                            accounts and subscriptions are not transferable and therefore cannot be sold or exchanged or transferred
                            in any way whatsoever.
                        </div>
                    </div>

                    <div>
                        <p>4. Subscriptions</p>
                        <div>
                            <div className="sub-para">
                                <p>4.1. Choosing a subscription</p>
                                Once you create an account, you can choose one of the subscriptions offered in AKADIMIA AI:
                                <p className="sub-para">1.“Free Use”: a free-of-charge subscription, which gives limited access to the
                                    Services. The “Free Use” is aimed at users who cannot afford any of our subscription fees. We
                                    reserve the right to deny the free use to anyone at any time at our own discretion.</p>
                                <p className="sub-para">2. “AKADIMIA AI Monthly”: a subscription-fee based service, which gives full
                                    access to the Services. You can become a subscriber to a paid subscription service (2) or (the
                                    “Paid Subscriptions”) by purchasing a subscription to the Services within the Apps, where
                                    allowed by the App marketplace partner (Apple iTunes Store).</p>
                                <p className="sub-para"></p>
                            </div>
                            <div className="sub-para">
                                <p>4.2. Maintaining a Paid Subscription</p>
                                Any of our paid subscriptions shall be paid in monthly, semi-annual or annual installments and
                                processed by the App marketplace partner through which you originally acquired the subscription. You
                                will only have access to a paid subscription while it is active and subsisting. Should you fail to
                                pay your subscription, your account will be downgraded to “Free Use”.
                                The renewal subscription fees will continue to be billed to the Payment Method you provided through
                                the marketplace, automatically until canceled. You must cancel your subscription before it renews
                                each billing period in order to avoid billing the next subscription fee to the Payment Method you
                                provided. Refunds cannot be claimed for any partial-month subscription period.
                                You can modify or cancel your paid subscription only through the App marketplace where you
                                originally acquired the subscription. To view, modify or cancel your subscription, you may be
                                required to be authenticated under the same user ID by the marketplace partner.
                            </div>
                            <div className="sub-para">
                                <p>4.3. Refunds</p>
                                Please note that if you purchase a subscription through the Apple iTunes Store or our iPhone
                                application, the sale is final, and we will not provide a refund. Your purchase will be subject to
                                Apple's applicable payment policy, which also may not provide for refunds.
                                Please note that the 14-days-after-purchase refund policy for EU residents does not apply for the
                                provided access to the digital product.
                            </div>
                        </div>
                    </div>

                    <div>
                        <p>5. Device Requirements</p>
                        To enjoy AKADIMIA AI via your smartphone or other Device, your Device must satisfy certain system
                        requirements. These requirements can be found on the Website and the Google and Apple App marketplaces
                    </div>

                    <div>
                        <p>6. CANCELLATION OF SERVICES</p>
                        <div className="sub-para">
                            <p>6.1. Cancellation by you</p>
                            You may cancel your account at any time by deleting your account in the app. This action is
                            irreversible. As soon as you delete your account, all the data associated with your use of the Services
                            will be removed permanently. Upon your account deletion, cancellation of a Paid Subscription is
                            effective at the end of the applicable billing period.You may cancel a Paid Subscription and downgrade
                            to “Free Use” at any time without deleting your account. Cancellation is effective at the end of the
                            applicable billing period.Please note that if you purchase a Paid Subscription through the Apple iTunes
                            Store or our iPhone application, you may cancel your subscription by canceling automatic renewal of paid
                            In App Subscriptions by selecting Manage App Subscriptions in your iTunes Account settings and selecting
                            the subscription you want to modify.
                        </div>
                        <div className="sub-para">
                            <p>6.2. Cancellation by us</p>
                            We may suspend or terminate your use of the Services as a result of your fraud or breach of any
                            obligation under these Terms. Such termination or suspension may be immediate and without notice.
                        </div>
                    </div>

                    <div>
                        <p>7. GENERAL PRACTICES REGARDING USE AND STORAGE</p>
                        You acknowledge that we may establish general practices and limits concerning use of the Services, including
                        without limitation the maximum period of time that data or other content will be retained by the Services
                        and the maximum storage space that will be allotted on our servers on your behalf. You agree that AKADIMIA
                        AI has no responsibility or liability for the deletion or failure to store any data or other content
                        maintained or uploaded by the Services. You acknowledge that we reserve the right to terminate accounts that
                        are inactive for an extended period of time. You further acknowledge that we reserve the right to change
                        these general practices and limits at any time, in its sole discretion, with or without notice.
                    </div>

                    <div>
                        <p>8. CONDITIONS OF USE</p>
                        <div className="sub-para">
                            <p>8.1. User conduct</p>
                            You are solely responsible for all code, video, images, information, data, text, software, music, sound,
                            photographs, graphics, messages or other materials (“content”) that you upload, post, publish or display
                            (hereinafter, “upload”) or email or otherwise use via the Services. The following are examples of the
                            kind of content and/or use that is illegal or prohibited by AKADIMIA AI. We reserve the right to
                            investigate and take appropriate legal action against anyone who, in our sole discretion, violates this
                            provision, including without limitation, removing the offending content from the Services, suspending or
                            terminating the account of such violators and reporting you to the law enforcement authorities. You
                            agree to not use the Services to:
                            <p className="sub-para">
                                1. Email or otherwise upload any content that (i) infringes any intellectual property or other
                                proprietary rights of any party; (ii) you do not have a right to upload under any law or under
                                contractual or fiduciary relationships; (iii) contains software viruses or any other computer code,
                                files or programs designed to interrupt, destroy or limit the functionality of any computer software
                                or hardware or telecommunications equipment; (iv) poses or creates a privacy or security risk to any
                                person; (v) constitutes unsolicited or unauthorized advertising, promotional materials, commercial
                                activities and/or sales, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” “contests,”
                                “sweepstakes,” or any other form of solicitation; (vi) is unlawful, harmful, threatening, abusive,
                                harassing, tortious, excessively violent, defamatory, vulgar, obscene, pornographic, libelous,
                                invasive of another’s privacy, hateful racially, ethnically or otherwise objectionable; or (vii) in
                                the sole judgment of AKADIMIA AI, is objectionable or which restricts or inhibits any other person
                                from using or enjoying the Services, or which may expose AKADIMIA AI or its users to any harm or
                                liability of any type;
                            </p>
                            <p className="sub-para">
                                2. Interfere with or disrupt the Services or servers or networks connected to the Services, or
                                disobey any requirements, procedures, policies or regulations of networks connected to the Services;
                                or
                            </p>
                            <p className="sub-para">
                                3. Violate any applicable local, state, national or international law, or any regulations having the
                                force of law;
                            </p>
                            <p className="sub-para">
                                4. Impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation
                                with a person or entity;
                            </p>
                            <p className="sub-para">
                                5. Solicit personal information from anyone under the age of 18;
                            </p>
                            <p className="sub-para">
                                6. Harvest or collect email addresses or other contact information of other users from the Services
                                by electronic or other means for the purposes of sending unsolicited emails or other unsolicited
                                communications;
                            </p>
                            <p className="sub-para">
                                7. Advertise or offer to sell or buy any goods or services for any business purpose that is not
                                specifically authorized;
                            </p>
                            <p className="sub-para">
                                8. Further or promote any criminal activity or enterprise or provide instructional information about
                                illegal activities; or
                            </p>
                            <p className="sub-para">
                                9. Obtain or attempt to access or otherwise obtain any materials or information through any means
                                not intentionally made available or provided for through the Services.
                            </p>
                        </div>
                        <div className="sub-para">
                            <p>8.2 Commercial use</p>
                            The Service is for your personal use only. Unless otherwise expressly authorized herein or in the
                            Services, you agree not to display, distribute, license, perform, publish, reproduce, duplicate, copy,
                            create derivative works from, modify, sell, resell, exploit, transfer or upload for any commercial
                            purposes, any portion of the Services, use of the Services, or access to the Services.
                        </div>

                        <div className="sub-para">
                            <p>8.3. Use of AKADIMIA AI by minors</p>
                            If you are under 13 years of age, you are authorized to use the Services, with or without registering,
                            only with the approval and consent of a parent or guardian. In addition, if you are under 18 years old,
                            you may use the Services, with or without registering, only with the approval of your parent or
                            guardian.
                        </div>
                    </div>

                    <div>
                        <p>9. INTELLECTUAL PROPERTY RIGHTS</p>
                        <div className="sub-para">
                            <p>9.1 Service content, software, and trademarks</p>

                            You acknowledge and agree that the Services may contain content or features (“Service Content”) that are
                            protected by copyright, patent, trademark, trade secret or other proprietary rights and laws. Except as
                            expressly authorized by AKADIMIA AI, you agree not to modify, copy, frame, scrape, rent, lease, loan,
                            sell, distribute or create derivative works based on the Services or the Service Content, in whole or in
                            part, except that the foregoing does not apply to your own User Content (as defined below) that you
                            legally upload to the Services. In connection with your use of the Services, you will not engage in or
                            use any data mining, robots, scraping or similar data gathering or extraction methods. If you are
                            blocked by AKADIMIA AI from accessing the Services (including by blocking your IP address), you agree
                            not to implement any measures to circumvent such blocking (e.g., by masking your IP address or using a
                            proxy IP address). Any use of the Services or the Service Content other than as specifically authorized
                            herein is strictly prohibited. The technology and software underlying the Services or distributed in
                            connection therewith are the property of AKADIMIA AI, our affiliates and our partners (the “Software”).
                            You agree not to copy, modify, create a derivative work of, reverse engineer, reverse assemble or
                            otherwise attempt to discover any source code, sell, assign, sublicense, or otherwise transfer any right
                            in the Software. Any rights not expressly granted herein are reserved by AKADIMIA AI. AKADIMIA AI’s name
                            and logos are trademarks and service marks of AKADIMIA AI (collectively the “AKADIMIA AI Trademarks”).
                            Other company, product, and service names and logos used and displayed via the Services may be
                            trademarks or service marks of their respective owners who may or may not endorse or be affiliated with
                            or connected to AKADIMIA AI. Nothing in these Terms of Service or the Services should be construed as
                            granting, by implication, estoppel, or otherwise, any license or right to use any of AKADIMIA AI
                            Trademarks displayed on the Services, without our prior written permission in each instance. All
                            goodwill generated from the use of AKADIMIA AI Trademarks will inure to our exclusive benefit.

                        </div>
                        <div className="sub-para">
                            <p>9.2 Third party material</p>
                            Under no circumstances will AKADIMIA AI be liable in any way for any content or materials of any third
                            parties (including users), including, but not limited to, for any errors or omissions in any content, or
                            for any loss or damage of any kind incurred as a result of the use of any such content. You acknowledge
                            that AKADIMIA AI does not pre-screen content, but that AKADIMIA AI and its designees will have the right
                            (but not the obligation) in their sole discretion to refuse or remove any content that is available via
                            the Services. Without limiting the foregoing, AKADIMIA AI and its designees will have the right to
                            remove any content that violates these Terms of Service or is deemed by AKADIMIA AI, in its sole
                            discretion, to be otherwise objectionable. You agree that you must evaluate, and bear all risks
                            associated with, the use of any content, including any reliance on the accuracy, completeness, or
                            usefulness of such content.


                        </div>
                        <div className="sub-para">
                            <p>9.3 User content transmitted through the Services</p>
                            With respect to the content or other materials you upload through the Services or share with other users
                            or recipients (collectively, “User Content”), you represent and warrant that you own all right, title
                            and interest in and to such User Content, including, without limitation, all copyrights and rights of
                            publicity contained therein. By uploading any User Content you hereby grant and will grant AKADIMIA and
                            its affiliated companies a nonexclusive, worldwide, royalty-free, fully paid up, transferable,
                            sublicensable, perpetual, irrevocable license to copy, display, upload, perform, distribute, store,
                            modify and otherwise use your User Content in connection with the operation of the Services or the
                            promotion, advertising or marketing thereof in any form, medium or technology now known or later
                            developed.

                            You acknowledge and agree that any questions, comments, suggestions, ideas, feedback or other
                            information about the Services (“Submissions”), provided by you to AKADIMIA AI are non-confidential and
                            AKADIMIA AI will be entitled to the unrestricted use and dissemination of these Submissions for any
                            purpose, commercial or otherwise, without acknowledgment or compensation to you.

                            You acknowledge and agree that AKADIMIA AI may preserve content and may also disclose content if
                            required to do so by law or in the good faith belief that such preservation or disclosure is reasonably
                            necessary to: (a) comply with legal process, applicable laws or government requests; (b) enforce these
                            Terms of Service; © respond to claims that any content violates the rights of third parties; or (d)
                            protect the rights, property, or personal safety of AKADIMIA AI, our users and the public. You
                            understand that the technical processing and transmission of the Services, including your content, may
                            involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical
                            requirements of connecting networks or devices.

                        </div>
                    </div>

                    <div>
                        <p>10. Third Party Websites</p>
                        The Services may provide, or third parties may provide, links or other access to other sites and resources
                        on the Internet. We have no control over such sites and resources and we are not responsible for and do not
                        endorse such sites and resources. You further acknowledge and agree that AKADIMIA AI will not be responsible
                        or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection
                        with use of or reliance on any content, events, goods or services available on or through any such site or
                        resource. Any dealings you have with third parties found while using the Services are between you and the
                        third party, and you agree that AKADIMIA AI is not liable for any loss or claim that you may have against
                        any such third party.

                    </div>

                    <div>
                        <p>11. WARRANTY, INDEMNITY AND LIABILITY</p>
                        <div className="sub-para">
                            <p>11.1 Indemnity and release</p>
                            You agree to release, indemnify and hold AKADIMIA AI and its affiliates and their officers, employees,
                            directors and agents (collectively, “Indemnitees”) harmless from any from any and all losses, damages,
                            expenses, including reasonable attorneys’ fees, rights, claims, actions of any kind and injury
                            (including death) arising out of or relating to your use of the Services, any User Content, your
                            connection to the Services, your violation of these Terms of Service or your violation of any rights of
                            another. Notwithstanding the foregoing, you will have no obligation to indemnify or hold harmless any
                            Indemnitee from or against any liability, losses, damages or expenses incurred as a result of any action
                            or inaction of such Indemnitee.

                        </div>
                        <div className="sub-para">
                            <p>11.2 Disclaimer of warranties</p>
                            YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE”
                            BASIS. COMPANY EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY,
                            INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                            PURPOSE, TITLE AND NON-INFRINGEMENT.AKADIMIA AI MAKES NO WARRANTY THAT (I) THE SERVICE WILL MEET YOUR
                            REQUIREMENTS, (II) THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (III) THE RESULTS
                            THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE, OR (IV) THE QUALITY OF
                            ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE
                            WILL MEET YOUR EXPECTATIONS.

                        </div>
                        <div className="sub-para">
                            <p>11.3 Limitation of liability</p>
                            YOU EXPRESSLY UNDERSTAND AND AGREE THAT AKADIMIA AI WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
                            SPECIAL, CONSEQUENTIAL, EXEMPLARY DAMAGES, OR DAMAGES FOR LOSS OF PROFITS INCLUDING BUT NOT LIMITED TO,
                            DAMAGES FOR LOSS OF GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF COMPANY HAS BEEN ADVISED OF
                            THE POSSIBILITY OF SUCH DAMAGES), WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR
                            OTHERWISE, RESULTING FROM: (I) THE USE OR THE INABILITY TO USE THE SERVICE; (II) THE COST OF PROCUREMENT
                            OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR
                            OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE SERVICE; (III)
                            UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (IV) STATEMENTS OR CONDUCT OF ANY
                            THIRD PARTY ON THE SERVICE; OR (V) ANY OTHER MATTER RELATING TO THE SERVICE.

                            SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OR EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR
                            EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE
                            LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU OR BE ENFORCEABLE WITH RESPECT TO YOU. IF YOU ARE
                            DISSATISFIED WITH ANY PORTION OF THE SERVICES OR WITH THESE TERMS OF SERVICE, YOUR SOLE AND EXCLUSIVE
                            REMEDY IS TO DISCONTINUE USE OF THE SERVICES

                        </div>
                    </div>

                    <p>12. YOUR PRIVACY
                        At AKADIMIA AI, we respect the privacy of our users. For details please see our Privacy Policy. By using the
                        Service, you consent to our collection and use of personal data as outlined therein.

                        Please contact us at support@akadimia.ai to report any violations of these Terms of Service or to ask us any
                        questions regarding these Terms of Service or our Services.
                    </p>

                    <div>
                        <p>13. APPLE APP STORE LEGAL INFORMATION</p>
                        <div>
                            <p>These Terms apply to your use of all the Services, including the iPhone application available via the
                                Apple, Inc. (“Apple”) App Store (the “Applications”) but the following additional terms also apply
                                to the Applications: </p>
                            <ul>
                                <li>Both you and AKADIMIA AI acknowledge that the Terms are concluded between you and AKADIMIA AI
                                    only, and not with Apple, and that Apple IS not responsible for the Application or the Content;
                                </li>
                                <li>The Application is licensed to you on a limited, non-exclusive, non-transferrable,
                                    non-sublicensable basis, solely to be used in connection with the Services for your private,
                                    personal, non-commercial use, subject to all the terms and conditions of this Agreement as they
                                    are applicable to the Services;</li>
                                <li>You will only use the Application in connection with an Apple device that you own or control;
                                </li>
                                <li>You acknowledge and agree that Apple has no obligation whatsoever to furnish any maintenance and
                                    support services with respect to the Application;</li>
                                <li>In the event of any failure of the Application to conform to any applicable warranty, including
                                    those implied by law, you may notify Apple of such failure; upon notification, Apple’s sole
                                    warranty obligation to you will be to refund to you the purchase price, if any, of the
                                    Application;</li>
                                <li>You acknowledge and agree that AKADIMIA, and not Apple, is responsible for addressing any claims
                                    you or any third party may have in relation to the Application;</li>
                                <li>You acknowledge and agree that in the event of any third party claim that the Application or
                                    your possession and use of the Application infringes that third party’s intellectual property
                                    rights, AKADIMIA AI, and not Apple, will be responsible for the investigation, defense,
                                    settlement and discharge of any such infringement claim;</li>
                                <li>You represent and warrant that you are not located in a country subject to a U.S. Government
                                    embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country
                                    and that you are not listed on any U.S. Government list of prohibited or restricted parties;
                                </li>
                                <li>Both you and AKADIMIA AI acknowledge and agree that, in your use of the Application, you will
                                    comply with any applicable third party terms of the agreement which may affect or be affected by
                                    such use; and</li>
                                <li>Both you and AKADIMIA AI acknowledge and agree that Apple and Apple’s subsidiaries are third
                                    party beneficiaries of this Agreement, and that upon your acceptance of this Agreement, Apple
                                    will have the right (and will be deemed to have accepted the right) to enforce this Agreement
                                    against you as the third party beneficiary hereof.</li>
                                <li>AKADIMIA AI and you acknowledge and agree that Apple, and Apple’s subsidiaries, are third party
                                    beneficiaries of these Terms of Service with respect to the Apple-Enabled Software, and that,
                                    upon your acceptance of the terms and conditions of these Terms of Service, Apple will have the
                                    right (and will be deemed to have accepted the right) to enforce these Terms of Service against
                                    you with respect to the Apple-Enabled Software as a third party beneficiary thereof.</li>
                            </ul>
                        </div>
                    </div>

                    <p>14. Governing Law
                        The Parties to this Agreement submit to the jurisdiction of the courts of the State of Illinois for the
                        enforcement of this Agreement or any arbitration award or decision arising from this Agreement. This
                        Agreement will be enforced or construed according to the laws of the State of Illinois.
                    </p>

                    <p>15. Notices
                        All notices to the Vendor under this Agreement are to be provided at the following address:
                        AKADIMIA AI: 65 E Monroe St Unit 4422 Chicago, IL, 60603-5743 United States
                    </p>
                    </div>
                    </article>
    );
};

export default TermsAndConditions;
