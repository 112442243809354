import OppenheimerImage from '../assets/November-2.png';
import AppleVision from '../assets/AVP.png';
import NovemberBlog from '../assets/ColorBlog.png';
import UpdateBlog from '../assets/UpdateBlog.png';
import Quickstart from '../assets/Quickstart.png';
import BlogT1Image from '../assets/blogt1.png';
import BlogT2Image from '../assets/blogt2.png';

import { useEffect, useState } from 'react';
import axios from 'axios';


// Function to convert a title to a URL-friendly slug
function titleToSlug(title) {
  return title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
}


const defaultBlogData = [
  {
    "id": 1,
    "title": "Oppenheimer's Ai Interview",
    "selectedDate": "Oct 30, 2023",
    "subtitle": "Exploring the Past Through AI: A Unique Podcast with Oppenheimer's AI on Akadimia AI",
    "image": OppenheimerImage,
    "bgColor": "#FBE6BF",
    "content": `
      <h3>Fusion of History and Technology: A Groundbreaking Podcast</h3>
      <p>In a groundbreaking event, Akadimia AI has taken a bold leap into the fusion of history and technology. They have recently released a fascinating podcast featuring an AI version of the renowned physicist J. Robert Oppenheimer. This podcast is not just a mere exploration of artificial intelligence; it's a journey through time, bringing a pivotal historical figure into our modern digital world.</p>
      <p>Watch the full podcast <a href="https://www.youtube.com/watch?v=K-at24PDVQI&t=114s" target="_blank">here</a>.</p>
  
      <h4>Realism and Interaction in AI</h4>
      <p>What makes this podcast truly unique is the level of interaction and realism it offers. Thanks to Akadimia AI's sophisticated algorithms and their Augmented Reality feature, viewers can experience a simulated Oppenheimer in an incredibly lifelike manner. This is not just about listening to a historical figure but rather interacting with a version of them brought to life through the marvels of AI.</p>
  
      <h4>Engage with AI Oppenheimer</h4>
      <p>The most exciting part? You don't have to be a passive listener. Akadimia AI extends the opportunity for everyone to engage with Oppenheimer's AI personally. By downloading the Akadimia AI app, you can have your own conversations with the AI version of Oppenheimer. This interactive feature opens up new avenues for learning, discussion, and exploration, making history more accessible and engaging than ever before.</p>
  
      <h4>Technological Prowess in Education and Entertainment</h4>
      <p>Furthermore, the podcast demonstrates the app's capabilities, as all the filmed scenes are created using the Akadimia AI app’s Augmented Reality feature. This technological prowess not only showcases the potential of AI in educational and entertainment sectors but also sets a new standard for historical engagement.</p>
  
      <h4>Invitation to Explore History through AI</h4>
      <p>Whether you're a student, a history enthusiast, or just curious about the possibilities of AI, this podcast and the Akadimia AI app offer an unparalleled experience. Dive into the past, engage with history, and witness the technological evolution that's changing the way we interact with the world around us.</p>
      <p>Download the Akadimia AI app today and start your own unique conversation with one of the most influential figures of the 20th century.</p>
    `
  },
  {
    "id": 2,
    "title": "Apple Vision Pro X Akadimia Ai",
    "selectedDate": "Sep 7, 2023",
    "image": AppleVision,
    "subtitle": "Akadimia AI App Coming to Apple Vision Pro: A New Era in AI Interaction",
    "bgColor": "#ffffff",
    "content": `
      <h3>Unveiling the Future of AI</h3>
      <p>Get ready to experience the future of artificial intelligence, as Akadimia AI announces its upcoming integration with Apple Vision Pro. This partnership marks the beginning of a new era in AI interaction, blending cutting-edge technology with an unparalleled user experience.</p>
  
      <h4>Collaboration Highlights:</h4>
      <ul>
        <li><strong>A Groundbreaking Platform</strong>: The Akadimia AI App on Apple Vision Pro is set to bring something extraordinary to the realm of AI & Education.</li>
        <li><strong>Revolutionizing AI Interaction</strong>: Although details are being closely guarded, the buzz is that this partnership will revolutionize how we interact with AI.</li>
        <li><strong>Expectations and Innovation</strong>: With Akadimia AI's innovation and Apple Vision Pro's advanced capabilities, the stage is set for a truly groundbreaking development.</li>
      </ul>
  
      <h4>What to Expect:</h4>
      <ul>
        <li><strong>Teaser for January 2024</strong>: Details are currently sparse, but a full reveal is planned for January 2024, hinting at a synergy between Akadimia AI's algorithms and Apple Vision Pro's features.</li>
        <li><strong>Limitless Potential</strong>: The collaboration is poised to redefine the boundaries of AI technology and user interaction.</li>
      </ul>
  
      <h4>Be Part of the Technological Leap</h4>
      <ul>
        <li><strong>A New Experience</strong>: This isn't just an app; it's an experience that will change how we view and interact with the digital world.</li>
        <li><strong>The Big Reveal</strong>: Stay tuned for the big reveal in January 2024. The future of AI is about to get even more exciting.</li>
      </ul>
  
      <p><strong>Stay Tuned!</strong> The future of AI is about to take a giant leap forward with Akadimia AI and Apple Vision Pro. Be ready for a journey into the future of AI, where technology meets imagination.</p>
    `
  },
  {
    "id": 3,
    "title": "November Updates!",
    "selectedDate": "Nov 28, 2023",
    "image": NovemberBlog,
    "subtitle": "Novembers Exciting App Updates: Revolutionizing AI Interaction in Akadimia AI",
    "bgColor": "#FC9D5B",
    "content": `
        <h3>Exciting Updates This Month</h3>
        <p><strong>November brings a wave of thrilling updates to Akadimia AI</strong>, each designed to enhance your interaction with history's greatest figures in ways never before imagined. We're setting new standards in AI realism and interactivity.</p>

        <h4>What's New:</h4>
        <ul>
            <li><strong>Improved Voice Realism</strong>: We've achieved a groundbreaking feat in voice cloning. Now, AI avatars like Oppenheimer and Malcolm X will speak with their actual voices, thanks to our advanced voice replication technology.</li>
            <li><strong>Enhanced Lipsync Capabilities</strong>: We've upgraded from 3 to 10 blendshapes for lip syncing, soon moving to 14, for a more natural and accurate synchronization of speech and facial movements.</li>
            <li><strong>Emotion Integration</strong>: Our AI avatars now incorporate facial expressions that reflect the context and emotion of their responses, enhancing the realism of communication.</li>
            <li><strong>Advanced Eye Tracking</strong>: AI avatars can now follow the camera with their gaze, creating a more engaging and lifelike interaction.</li>
        </ul>

    

        <h4>Introducing the 'Museum' Feature</h4>
        <ul>
            <li><strong>Virtual Museums</strong>: Walk through museums guided by AI avatars of historical figures like Cyrus the Great or Leonardo da Vinci. Each avatar now has its own virtual museum, offering a unique journey through time and culture.</li>
        </ul>

        <h4>Our Milestones:</h4>
        <ul>
            <li><strong>First Virtual AI-Powered Museum</strong>: This isn't just a museum; it's an immersive experience that brings history to life.</li>
            <li><strong>Expanded AI Avatar Roster</strong>: New additions include Genghis Khan, Adam Smith, Mansa Musa, and more, each offering unique insights from their eras.</li>
        </ul>

        <p>Your Participation Matters: Explore these new features and experience a revolution in AI interaction with Akadimia AI. We're eager to hear your feedback and see how you interact with these exciting updates!</p>

        <h4>Frequently Asked Questions (FAQs)</h4>
        <ol>
            <li>How do I access the new museum feature? - You can find the virtual museum next to the Augmented Reality button in the app.</li>
            <li>Will there be more historical figures added to the roster? - Yes, we plan to continuously expand our roster of AI avatars.</li>
        </ol>

        <p><strong>Stay Tuned!</strong> These updates represent our commitment to pushing the boundaries of AI technology, making history not only accessible but truly alive. Stay tuned for more exciting developments!</p>
    `
  },
  {
    "id": 4, // Ensure this ID is unique within the array
    "title": "Akadimia AI’s New UI",
    "selectedDate": "Feb 9, 2024", // Adjust the date to your actual publishing date
    "subtitle": "A groundbreaking update transforming your interaction with history’s greatest minds.",
    "image": UpdateBlog, // This will be the main image for the blog post
    "bgColor": "#CBD7E5", // Choose a background color that fits your design
    "content": `
    <p>In an era where technology meets education, we at Akadimia Ai are thrilled to unveil a groundbreaking update to our app, set to launch next week. Our mission has always been to make learning not just accessible but truly captivating. With our latest update, we’re turning this vision into reality by transforming the way you interact with history’s greatest minds.</p>
    
    <h2>A Leap into the Future with Our New UI</h2>
    <p>Imagine opening an app and being immediately transported into a conversation with Thomas Edison or Nikola Tesla. That’s the seamless experience our new UI offers. Gone are the days of navigating through cumbersome menus to start your learning journey. Now, the moment you launch Akadimia AI, you’re in the camera view, ready to summon historical figures into your space with just a tap.</p>
    <img src="${BlogT1Image}" alt="New UI Preview" style="float:right; margin-left: 40px; max-width: 30%; height: auto;">
  
    <h2>Dual Conversations with Icons of History</h2>
    <p>For the first time, our app allows you to interact with more than one historical figure in augmented reality. Picture a discussion between Edison and Tesla, unfolding right in your living room. This feature is more than just an update; it’s a gateway to unparalleled learning experiences, where the juxtaposition of ideas from different eras can spark curiosity and inspire deep understanding.</p>

    <h2>From AR to Virtual Museums: A Seamless Transition</h2>
    <p>Our revamped UI eliminates the friction of switching between AR and immersive museum tours. While engaging with Charles Darwin in AR, a simple tap on the screen reveals a ‘museum’ button. Pressing it whisks you away to a virtual museum, where Darwin himself becomes your personal guide, showcasing artifacts, portraits, and 3D items, each with a story waiting to be told. This fluid transition is not just about the technology; it’s about creating a narrative that enriches your learning.</p>

    <h2>Simplicity Meets Elegance</h2>
    <p>Inspired by our work on the Apple Vision Pro app, we’ve reimagined our UI to embody elegance and simplicity. With translucent, see-through buttons and a minimalist design, the new interface drastically reduces the number of taps and clicks, immersing you into the experience from the moment you begin. This design philosophy ensures that your focus remains on learning, unhampered by the technology that facilitates it.</p>
    
    <h2>Looking Forward</h2>
    <p>As we prepare to roll out this update, our excitement is palpable. We believe that by enhancing the user interface, we’re not just improving an app; we’re reshaping the future of education. This update reflects our commitment to innovation and our passion for making history not only accessible but truly alive.</p>
    
    <p>Join us on this journey to discover history like never before. Stay tuned for the update next week, and get ready to experience the past in a way you’ve never imagined.</p>
  `
  },
  {
    "id": 5, // Ensure this ID is unique within the array
    "title": "Akadimia AI Quickstart Guide",
    "selectedDate": "Feb 27, 2024", // Adjust the date to your actual publishing date
    "subtitle": "Getting started with Akadimia AI is easier than ever.",
    "image": Quickstart, // This will be the main image for the blog post
    "bgColor": "#c4c3c0", // Choose a background color that fits your design
    "content": `
  <p> Follow our quickstart guide to begin your journey into the world of AI and historical exploration.</p>
  <iframe width="1005" height="565" src="https://www.youtube.com/embed/70YPsrfEUcY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  <p>Follow the steps in the video to navigate Akadimia's features, and start engaging with the historical figures in augmented reality. It's time to make learning history an immersive and interactive experience!</p>
  `
  }
];

const BlogData = () => {
  const [blogData, setBlogData] = useState([]);

  useEffect(() => {
    // Fetch blog data from the server
    const fetchBlogData = async () => {
      try {
        const response = await axios.get('https://server-puce-iota.vercel.app/api/blogs');
        const apiData = response.data;

        // Add slug property to each blog object from the API
        const updatedApiData = apiData.map(post => ({
          ...post,
          slug: titleToSlug(post.title)
        }));

        // Merge default blog data with data from the API
        const mergedData = [...defaultBlogData, ...updatedApiData];

        // Add slug property to each blog object after merging
        const updatedMergedData = mergedData.map(post => ({
          ...post,
          slug: titleToSlug(post.title)
        }));

        // Set the updated blog data in the state
        setBlogData(updatedMergedData);
      } catch (error) {
        console.error('Error fetching blog data:', error.message);
      }
    };

    // Call the fetch function
    fetchBlogData();
  }, []); // Empty dependency array ensures useEffect runs only once


  // Return blogData so it can be used in another component
  return blogData;
};

export default BlogData;