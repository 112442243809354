import React from 'react';
import './Footer.css';
import logoFullWR1 from '../assets/akadimia-logo.png';  // Update the path
import tiktok from '../assets/tiktok.png';
import instagram from '../assets/instagram.png';
import twitter from '../assets/twitter.png';
import youtube from '../assets/youtube.png';

const Footer = () => {
  return (
    <div className="footer">
      <img src={logoFullWR1} alt="Logo" className="footer-logo" />
      <div className="footer-social-icons">
        <a href="https://www.tiktok.com/@akadimiaai" target="_blank" rel="noopener noreferrer">
          <img src={tiktok} alt="TikTok" />
        </a>
        <a href="https://www.instagram.com/akadimiaai/" target="_blank" rel="noopener noreferrer">
          <img src={instagram} alt="Instagram" />
        </a>
        <a href="https://twitter.com/AkadimiaAi" target="_blank" rel="noopener noreferrer">
          <img src={twitter} alt="Twitter" />
        </a>
        <a href="https://www.youtube.com/@akadimiaai1854" target="_blank" rel="noopener noreferrer">
          <img src={youtube} alt="YouTube" />
        </a>
      </div>
      <div>© 2023 Akadimia AI. All rights reserved.</div>
    </div>
  );
};

export default Footer;
