import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Import Helmet
import './Blog.css';
// import blogData from './blogData'; // Adjust the path to where your blogData.js is located
import BlogData from './blogData';
const Blog = ({ setHeaderBgColor, metaKeywords }) => {
  const blogData = BlogData();



  // Sort blog posts by date in descending order (newest first)
  const sortedBlogData = blogData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  useEffect(() => {
    // Reset the header color to default when the Blog component mounts
    setHeaderBgColor('#FFFFFF'); // Replace 'defaultColor' with your actual default header color

    // Optional: Reset color when component unmounts
    // return () => setHeaderBgColor('anotherColor');
  }, [setHeaderBgColor]);

  return (
    <div className="blog-container">
      <Helmet>
        <title>Blog - Akadimia AI</title>
        <meta name="keywords" content={metaKeywords} />
        <meta name="description" content="Read the latest updates, articles, and insights on our blog." />
        <meta property="og:title" content="Akadimia AI - Learn Like a Legend, Become a Legend" />
        <meta property="og:description" content="Step into the future of learning with Akadimia AI, an immersive educational platform that brings history's icons as your AI mentor." />
        <meta property="og:image" content="https://akadimia.ai/static/media/November-2.d8ecf9642dc0450dd2f5.png" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://akadimia.ai/" />
        <link rel="canonical" href="https://akadimia.ai/" />

        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "Blog",
        "name": "Blog - Akadimia AI",
        "description": "Read the latest updates, articles, and insights on our blog.",
        "url": "https://akadimia.ai/blog"
      }
    `}
        </script>
      </Helmet>
      <header className="blog-header">
        <h1>Blog</h1>
        <div className="hr-wrapper">
          <hr />
        </div>
      </header>
      <h2 className="latest-updates">Latest Updates</h2>
      <div className="blog-grid">
        {sortedBlogData.map(blog => (

          <Link to={`/blog/${blog.slug}`} key={blog.id} className="blog-post">
            <div className="blog-image-wrapper">
              <img src={blog.image} alt={blog.title} />
            </div>
            <div className="blog-info">
              <h3>{blog.title}</h3>
              <p className="blog-date">
                {blog.selectedDate && blog.selectedDate !== "" ? (
                  new Date(blog.selectedDate).toLocaleString('en-US', {
                    month: 'short',
                    day: '2-digit',
                    year: 'numeric'
                  })
                ) : (
                  new Date(blog.createdAt).toLocaleString('en-US', {
                    month: 'short',
                    day: '2-digit',
                    year: 'numeric'
                  })
                )}
              </p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Blog;
