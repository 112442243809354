import React, { useState } from 'react';
import './ContactUsPage.css';
import EnricoFermi from '../assets/EnricoFermi_171.png';
import SendMessageBtn from '../assets/SendMessagebtn.png';
import { Helmet } from 'react-helmet';


function ContactUsPage() {
  const [message, setMessage] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [text, setText] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name && email && text) {
      // Here you would typically send the data to your server or email service
      setMessage("Your message has been sent!");
    } else {
      setMessage("Please fill out all the fields.");
    }
  };

  return (
    <>
       <Helmet>
        <title>End User License Agreement - Akadimia AI</title>
        <meta name="description" content="Read the End User License Agreement (EULA) for Akadimia AI's Services. Understand the terms and conditions governing the use of our website, mobile app, and software." />
      </Helmet>
     <div className="contact-us-container">
      <div className="left-container">
        <h1 className="get-in-touch">GET IN TOUCH</h1>
        <div className="form-container">
          <form className="contact-form" onSubmit={handleSubmit}>
            <input
              type="text"
              className="input-field"
              placeholder="Enter your name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="email"
              className="input-field"
              placeholder="Enter your email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <textarea
              className="input-field"
              rows="4"
              placeholder="Enter your message"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            <input
              type="submit"
              alt="Send Message"
              className="send-message-btn"
            />
          </form>
          {message && <div className="message">{message}</div>}
        </div>
      </div>
      <div className="right-container">
        <img src={EnricoFermi} alt="Enrico Fermi" className="right-image" />
      </div>
    </div>
    </>
   
  );
}

export default ContactUsPage;
